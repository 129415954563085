@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400&family=Fauna+One&display=swap");

/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
  background: #f8f8f5;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.group:hover .opacity-0 {
  opacity: 100;
}



/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  height: 40px;
  --g: radial-gradient(farthest-side,#f5f5f5 calc(95% - 3px),#fff calc(100% - 3px) 98%,#f8f8f5 101%) no-repeat;
  background: var(--g), var(--g), var(--g);
  background-size: 30px 30px;
  animation: l9 1s infinite alternate;
}
@keyframes l9 {
  0% {
    background-position: 0 50%, 50% 50%, 100% 50%;
  }
  20% {
    background-position: 0 0, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0 100%, 50% 0, 100% 50%;
  }
  60% {
    background-position: 0 50%, 50% 100%, 100% 0;
  }
  80% {
    background-position: 0 50%, 50% 50%, 100% 100%;
  }
  100% {
    background-position: 0 50%, 50% 50%, 100% 50%;
  }
}

#magnifier {
  border: 2px solid #000;
  border-radius: 50%;
  cursor: none;
  height: 100px;
  width: 100px;
  position: absolute;
  overflow: hidden;
  display: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  background-repeat: no-repeat;
}